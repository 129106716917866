import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import ZiptiInput from "./zipti_input"

export interface ZiptiNumberInputProps {
  value: number
  setValue: Function
}

export const ZiptiNumberInput = (props: ZiptiNumberInputProps) => {
  const [value, setValue] = useState(Number(props.value))
  // TODO: Move this state up...
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <ZiptiInput
      onChange={e => {
        setValue(Number(e.target.value))
        props.setValue(Number(e.target.value))
      }}
      placeholder={value}
      type="number"
      w={{ base: "14rem", md: "28rem" }}
    ></ZiptiInput>
  )
}

import React from "react"
import ZiptiContainer from "../shared/zipti_container"
import ZiptiText from "../shared/zipti_text"
import { HStack } from "@chakra-ui/layout"
import { Table, Tbody, Tr, Td, TableCaption } from "@chakra-ui/table"
import { Box } from "@chakra-ui/layout"
import { Switch } from "@chakra-ui/react"
import { GoPrimitiveDot } from "react-icons/go"

import ZiptiButton from "../shared/zipti_button"
import { useContext } from "react"
import { ApiContext, resetUser, UserContext } from "../user_wrapper"
import { useEffect } from "react"
import { useState } from "react"
import { StripeOnboardingStatus } from "../../services/api_types"
import { ERROR_TYPES } from "../../services/api"

interface StripeStatusSectionProps {
  section: string
  isChecked: boolean
  switchProps: any
}

const StripeStatusSection: React.FC<StripeStatusSectionProps> = props => {
  return (
    <HStack mt="0.5vh">
      <ZiptiText>{props.section}</ZiptiText>
      <GoPrimitiveDot color="green" />
      {/* <Switch
        isChecked={props.isChecked}
        colorScheme="purple"
        {...props.switchProps}
      /> */}
    </HStack>
  )
}

export interface StripeStatusCardProps {
  mt?: string
}

const StripeStatusCard: React.FC<StripeStatusCardProps> = props => {
  const apiClient = useContext(ApiContext)
  const user = useContext(UserContext)
  const [hasStripeAccount, setHasStripeAccount] = useState<boolean>(true)
  const [stripeStatus, setStripeStatus] = useState<
    StripeOnboardingStatus | undefined
  >(undefined)

  useEffect(() => {
    const stripeStatusCardUseEffect = async () => {
      const res = await apiClient.getStripeOnboardingStatus()
      console.log("RESRES", res)
      if (!!res.error) {
        if (res.error.message === ERROR_TYPES.NO_STRIPE_ACCOUNT_EXISTS) {
          setHasStripeAccount(false)
        }

        // TODO handle UNKNOWN_ERROR better
        return
      }

      setStripeStatus(res.data)
    }

    stripeStatusCardUseEffect()
  }, [])

  const registerStripeAccount = async () => {
    const res = await apiClient.registerStripeAccount()
    console.log("RES", res)
    user.resetUser()
    return res.data
  }

  const getSetupLink = async () => {
    if (typeof window !== "undefined") {
      const stripeLinkRes = await apiClient.createStripeLink()
      if (!!stripeLinkRes.error) {
        console.log(stripeLinkRes.error)
        return
      }

      if (!!stripeLinkRes.data?.account_link_url) {
        window.location.replace(stripeLinkRes.data?.account_link_url)
      }
    }
  }

  const confirmStripeSetup = async () => {
    const stripeLinkRes = await apiClient.createStripeLink()
    window.location.replace(stripeLinkRes.data?.account_link_url)
    const enableStripeRes = await apiClient.enableStripe()
    if (enableStripeRes) {
      console.log("enabled!")
      user.resetUser()
      return
    }

    console.log("not ready")
  }

  const renderStripeButton = () => {
    const isSetupStep =
      !stripeStatus || !Object.values(stripeStatus).every(x => x)
    const onClickFunction = (() => {
      if (!hasStripeAccount) {
        return registerStripeAccount
      }
      if (isSetupStep) {
        return getSetupLink
      }
      return confirmStripeSetup
    })()
    const buttonCopy = (() => {
      if (!hasStripeAccount) {
        return "Create Account"
      }
      if (isSetupStep) {
        return "Setup Account"
      }
      return "Visit Stripe"
    })()
    return (
      <ZiptiButton
        mt="2vh"
        h="5vh"
        w={{ base: "40vw", md: "30vw", lg: "20vw" }}
        onClick={onClickFunction}
      >
        {buttonCopy}
      </ZiptiButton>
    )
  }

  const accountExists = "Account Exists"
  const detailsSubmitted = "Details Submitted"
  const chargesEnabled = "Charges Enabled"
  const payoutsEnabled = "Payouts Enabled"

  return (
    <ZiptiContainer h="16rem" pt="2rem" {...props}>
      <Table ml="4rem" colorScheme="purple" size="sm" variant="unstyled">
        <Tbody>
          <Tr>
            <Td>
              <ZiptiText textAlign="right" w="8rem">
                {accountExists}
              </ZiptiText>
            </Td>
            <Td>
              <Box ml="-10vw">
                <GoPrimitiveDot
                  color={hasStripeAccount ? "green" : "#d3d3d3"}
                />
              </Box>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <ZiptiText textAlign="right" w="8rem">
                {detailsSubmitted}
              </ZiptiText>
            </Td>
            <Td>
              <Box ml="-10vw">
                <GoPrimitiveDot
                  color={stripeStatus?.details_submitted ? "green" : "#d3d3d3"}
                />
              </Box>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <ZiptiText textAlign="right" w="8rem">
                {chargesEnabled}
              </ZiptiText>
            </Td>
            <Td>
              <Box ml="-10vw">
                <GoPrimitiveDot
                  color={stripeStatus?.charges_enabled ? "green" : "#d3d3d3"}
                />
              </Box>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <ZiptiText textAlign="right" w="8rem">
                {payoutsEnabled}
              </ZiptiText>
            </Td>
            <Td>
              <Box ml="-10vw">
                <GoPrimitiveDot
                  color={stripeStatus?.payouts_enabled ? "green" : "#d3d3d3"}
                />
              </Box>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {renderStripeButton()}
    </ZiptiContainer>
  )
}

export default StripeStatusCard

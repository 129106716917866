import React from "react"
import { HStack } from "@chakra-ui/react"
import ZiptiText from "./zipti_text"
import { GoPrimitiveDot } from "react-icons/go"

interface ZiptiModalValidationProps {
  validator: Function
  profileItem: any
  title: any
}

const ZiptiModalValidation: React.FC<ZiptiModalValidationProps> = props => {
  return (
    <HStack width="85%" display="flex" justifyContent="space-between" mt="2rem">
      <ZiptiText>{generateTitle(props.title)}</ZiptiText>
      <GoPrimitiveDot
        color={props.validator(props.profileItem) ? "green" : "#d3d3d3"}
      />
    </HStack>
  )
}

const generateTitle = title => {
  switch (title) {
    case "ask_price":
      return "Set Your Ask Price"
    case "profile_about":
      return "Fill Out You About"
    case "profile_intro_video_url":
      return "Upload Your Intro Video"
    case "profile_offering":
      return "Fill Out Offer Section"
    case "profile_response_time":
      return "Set Your Gaurantee"
    case "profile_slug":
      return "Add Your Handle"
    case "currency_code":
      return "Select Your Currency"
    case "profile_image_url":
      return "Upload Profile Picture"
    case "stripe_enabled":
      return "Setup Stripe Payments"
    case "name":
      return "Add Your Name"
    default:
      "Title Placeholder"
  }
}

export default ZiptiModalValidation

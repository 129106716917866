import React from "react"
import { useContext } from "react"
import playTriangle from "../../images/playTriangle.svg"
import Wrapper from "../shared/wrapper"
import ZiptiText from "../shared/zipti_text"
import ZiptiButton from "../shared/zipti_button"
import ZiptiContainer from "../shared/zipti_container"
import TitledInput from "../shared/titled_input"
import TitledInputLarge from "../shared/titled_input/titled_input_large"
import { ApiContext, ConversationsContext, UserContext } from "../user_wrapper"
import UnderReviewCard from "./under_review_card"
import StripeStatusCard from "./stripe_status_card"
import { Container, HStack, VStack } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/select"
import { useEffect } from "react"
import { ERROR_TYPES } from "../../services/api"
import { useState } from "react"
import { ProfileResponseData } from "../../services/api_types"
import ZiptiInput from "../shared/zipti_input"
import ZiptiTextarea from "../shared/zipti_textarea"
import { Link, navigate } from "gatsby"
import { Image } from "@chakra-ui/image"
import { FileInput, FileInputTypes } from "../shared/file_input/FileInput"
import { ZiptiNumberInput } from "../shared/zipti_number_input"
import { CURRENCIES } from "./currencies"
import { MdContentCopy } from "react-icons/md"
import ZiptiDrawer from "../shared/zipti_drawer"
import VideoPlayer from "../shared/modal/video_play_modal"
import ZiptiExpertModal from "../shared/ZiptiExpertModal"
import { Spinner } from "@chakra-ui/spinner"
import ZiptiModalValidation from "../shared/zipti_modal_validation"
import { FileUploadType } from "../../services/api_types"
import { uploadFile } from "../../services/api_utils"
import "./edit_profile.css"
import { Box, Divider } from "@chakra-ui/layout"
import _ from "lodash"
import ZiptiModal from "../shared/zipti_modal"
import { useDisclosure } from "@chakra-ui/react"
import VideoPlayIcon from "../shared/video_capture/video_play_icon"
import ImageCropper from "./image_cropper"
import ZiptiCropperModal from "../shared/zipti_cropper_modal"
import { COLORS } from "../shared/zipti_colors"
import VideoPreviewModal from "../shared/modal/video_preview_modal"

// TODO: So the api call that retrieves the image in S3 is erroring out with what I believe is a 404...

enum DRAWER_TYPES {
  WATCH = "watch",
  DEFAULT = "default",
  UPLOAD = "upload",
}

enum MODAL_TYPES {
  NAVIGATE_TO_HOME = "home",
  NAVIGATE_TO_PROFILE = "profile",
  CROPPER = "cropper",
}

const EditProfile: React.FC = (props: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const conversations = useContext(ConversationsContext)
  const [networkSpeed, setNetworkSpeed] = useState(3500000)
  const yourProfileCopy = "Your Profile"
  const yourAccountCopy = "Your Account"
  const stripeStatusCopy = "Setup Payments"
  const [tempProfileImage, setTempProfileImage] = useState("")
  const [tempPhotoName, setTempPhotoName] = useState("")
  const [enableActivateButton, setEnableActivateButton] = useState<Boolean>(
    false
  )
  const [uploadProfilePhotoSwitch, setUploadProfilePhotoSwitch] = useState(
    false
  )

  const [originalProfile, setOriginalProfile] = useState<
    ProfileResponseData | undefined
  >(undefined)
  const [profile, setProfile] = useState<ProfileResponseData | undefined>(
    undefined
  )
  const user = useContext(UserContext)
  const apiClient = useContext(ApiContext)

  const updateCurrentAndOriginalProfile = (
    profileData: ProfileResponseData
  ) => {
    setProfile(profileData)
    setOriginalProfile(profileData)
  }

  const resetOriginalProfile = async () => {
    const res = await apiClient.getProfile()
    if (!!res.error || !res.data) {
      console.log("issue fetching profile")
      console.log(res.error)
      return
    }

    setOriginalProfile(res.data)
  }

  const editProfileUseEffect = async () => {
    const res = await apiClient.getProfile()
    if (res.error?.message === ERROR_TYPES.NO_PROFILE_FOR_ACCOUNT) {
      console.log("no profile")

      const profileRes = await apiClient.getProfile()
      if (!!profileRes.error && user.data?.account?.is_expert) {
        alert("unknown error fetching profile after creating")
      }
      updateCurrentAndOriginalProfile(profileRes.data)
      return
    }

    if (!!res.error && user.data?.account?.is_expert) {
      alert("unknown error fetching profile")
      return
    }

    updateCurrentAndOriginalProfile(res.data)

    const accountRes = await apiClient.getAccount()
    if (!!accountRes.error) {
      alert("cant fetch account")
      return
    }
    console.log(accountRes)
    setName(accountRes.data?.account?.name)
    setEmail(accountRes.data?.account?.email)

    if (profile) {
      const profilePass = checkProfileForActivation(profile)
      const accountPass = checkAccountForActivation(user.data?.account)
      if (profilePass && accountPass) {
        setEnableActivateButton(true)
      } else {
        setEnableActivateButton(false)
      }
    }
    await user.resetUser()
    if (user.data?.account?.is_expert) {
      openOnboarding()
    }
  }

  useEffect(() => {
    editProfileUseEffect()
  }, [
    user.data?.account?.is_expert,
    enableActivateButton,
    uploadProfilePhotoSwitch,
  ])

  const notFilledOutCopy = "Fill out your profile below."
  const afterFilledOutCopy = "All fields need to be filled out before review."
  const underReviewCopy = "Your profile is currently under review."
  const approvalCopy = "We'll email you when it's been approved."

  const renderUnderReviewCard = () => {
    if (!user.data?.account?.has_filled_out_profile) {
      return (
        <UnderReviewCard
          mt="5vh"
          title={notFilledOutCopy}
          subtitle={afterFilledOutCopy}
          openOnboarding={openOnboarding}
        />
      )
    }

    if (!user.data.account.is_approved_profile) {
      return (
        <UnderReviewCard
          mt="5vh"
          title={underReviewCopy}
          subtitle={approvalCopy}
          openOnboarding={openOnboarding}
        />
      )
    }
  }

  const renderStripeStatusCardHeader = () => {
    if (!isStripeEnabled()) {
      return (
        <ZiptiText mt="5vh" fontWeight="bold" fontSize="2.5vh">
          {stripeStatusCopy}
        </ZiptiText>
      )
    }
  }

  const isStripeEnabled = (): boolean => {
    return !!user.data?.account?.stripe_enabled
  }

  const renderStripeStatusCard = () => {
    return <StripeStatusCard mt="2vh" />
  }

  const saveProfileUpdates = async () => {
    if (!profile || !originalProfile) {
      return
    }
    const accountSettingsAreEqual =
      name === user.data?.account?.name && email === user?.data?.account?.email
    if (!accountSettingsAreEqual) {
      if (name !== user.data?.account?.name) {
        await apiClient.updateAccount({ name: name })
      }

      if (email !== user.data?.account?.email) {
        console.log("email updates not implemented")
      }
    }

    const profilesAreEqual = _.isEqual(profile, originalProfile)
    if (!profilesAreEqual) {
      await apiClient.updateProfile({
        profile: profile,
      })
    }

    editProfileUseEffect()
  }

  const renderActivateExpertButton = () => {
    const accountSettingsAreEqual = name === user?.data?.account?.name
    const profilesAreEqual = _.isEqual(profile, originalProfile)
    console.log("ENABLE", enableActivateButton)
    if (profile?.is_active) {
      return
    }
    if (!profilesAreEqual || !accountSettingsAreEqual) {
      return
    }
    if (enableActivateButton) {
      return (
        <Box
          paddingInline={{ base: 0, md: "30%" }}
          position="fixed"
          bottom="0px"
          w="100%"
          h="10vh"
          bgColor="#297bae"
        >
          <HStack display="flex" justifyContent="center" w="100%" h="100%">
            <ZiptiButton
              w="12rem"
              mb="2rem"
              mt="2rem"
              onClick={() => handleActivateSubmit()}
            >
              Activate Expert Profile
            </ZiptiButton>
          </HStack>
        </Box>
      )
    }
  }

  const renderSaveButton = () => {
    if (!profile || !originalProfile) {
      return
    }

    const accountSettingsAreEqual = name === user?.data?.account?.name
    const profilesAreEqual =
      JSON.stringify(profile) === JSON.stringify(originalProfile)

    if (!profilesAreEqual || !accountSettingsAreEqual) {
      return (
        <Box
          paddingInline={{ base: 0, md: "30%" }}
          position="fixed"
          bottom="0px"
          w="100%"
          h="10vh"
          bgColor="#297bae"
        >
          <HStack display="flex" justifyContent="center" w="100%" h="100%">
            <ZiptiButton
              onClick={saveProfileUpdates}
              w={{ base: "40vw", md: "10vw" }}
              borderRadius="md"
            >
              Save Changes
            </ZiptiButton>
          </HStack>
        </Box>
      )
    }
  }

  const [name, setName] = useState<string | undefined>(user.data?.account?.name)
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.WATCH)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined)
  const [modalType, setModalType] = useState(MODAL_TYPES.NAVIGATE_TO_HOME)
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false)
  const [email, setEmail] = useState<string | undefined>(
    user.data?.account?.email
  )
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value.toLowerCase())
    setEmailValidationToggle(false)
  }
  const handleUpdateEmailSubmit = async () => {
    const validEmail = validateEmail(email)
    if (!validEmail || email.length < 5) {
      setEmailValidationToggle(true)
      return
    }
    setEmailValidationToggle(false)
    const res = await apiClient.sendUpdateEmail(email)
    window.confirm("Please check your email for confirmation link.")
  }
  const [emailValidationToggle, setEmailValidationToggle] = useState<boolean>(
    false
  )
  const [toggleSpinner, setToggleSpinner] = useState<boolean>(false)

  const verifyString = item => {
    return typeof item === "string" && item.length > 1 ? true : false
  }
  const verifyNumber = item => {
    return typeof item === "number" ? true : false
  }
  const verifyIsTrue = item => {
    return item ? true : false
  }
  const validateProfileObj = {
    ask_price: verifyNumber,
    profile_about: verifyString,
    profile_intro_video_url: verifyString,
    profile_response_time: verifyString,
    profile_slug: verifyString,
    currency_code: verifyString,
  }

  const validateProfileObjOffering = {
    profile_offering_one: verifyString,
    profile_offering_two: verifyString,
    profile_offering_three: verifyString,
  }

  const checkProfileForActivation = profile => {
    const checkOfferingArray = Object.entries(
      validateProfileObjOffering
    ).filter(item => {
      if (item[1](profile[item[0]])) {
        return true
      }
    })
    const checkArray = Object.entries(validateProfileObj).filter(item => {
      if (item[1](profile[item[0]])) {
        return true
      }
    })
    const returnBoolean =
      checkArray.length < 6 || checkOfferingArray.length < 1 ? false : true
    return returnBoolean
  }

  function validateEmail(email: string | undefined) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const validateAccountObj = {
    profile_image_url: verifyString,
    name: verifyString,
    stripe_enabled: verifyIsTrue,
  }

  const checkAccountForActivation = account => {
    const checkArray = Object.entries(validateAccountObj).filter(item => {
      if (item[1](account[item[0]])) {
        return true
      }
    })
    return checkArray.length < 3 ? false : true
  }

  const updateField = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    if (!e || !e.target) {
      return
    }
    const newProfile: ProfileResponseData = { ...profile }
    newProfile[field] = e.target.value
    setProfile(newProfile)
  }

  const updateInstagram = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "profile_instagram_link")
  }

  const updateAbout = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "profile_about")
  }

  const updateOfferingOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "profile_offering_one")
  }

  const updateOfferingTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "profile_offering_two")
  }

  const updateOfferingThree = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "profile_offering_three")
  }

  const updateResponseTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== undefined) {
      updateField(e, "profile_response_time")
    }
  }

  const updateCurrencyCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e, "currency_code")
  }

  const updateAskPrice = (newPrice: number) => {
    if (!newPrice) newPrice = 0
    const newProfile: ProfileResponseData = {
      ...profile,
      ask_price: newPrice,
    }
    setProfile(newProfile)
  }

  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target) {
      return
    }

    setName(e.target.value)
  }

  const updateProfilePhoto = async (photoFile: File) => {
    setToggleSpinner(true)
    const image_url = await uploadFile(
      apiClient,
      photoFile,
      FileUploadType.UPDATE_PROFILE_IMAGE,
      user.data?.account?.account_id
    )
    setToggleSpinner(false)
    if (!image_url) return
    setModalType(MODAL_TYPES.CROPPER)
    setTempProfileImage(image_url)
    setTempPhotoName(photoFile?.name)
    onOpen()

    const res = await apiClient.uploadProfilePhoto(image_url)
    if (!!res.error || !res.data) {
      console.log("error uploading photo")
      console.log(res.error)
    }
    user.resetUser()
  }

  const onCropperClose = () => {
    setUploadProfilePhotoSwitch(!uploadProfilePhotoSwitch)
    onClose()
  }

  const [loadingFileSize, setLoadingFileSize] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const updateIntroVideo = async (videoFile: File) => {
    const networkRes = await apiClient.networkSpeedTest()
    setNetworkSpeed(networkRes.data?.mbps)
    setDrawerType(DRAWER_TYPES.UPLOAD)
    setIsLoading(true)
    setLoadingFileSize(videoFile.size)
    onDrawerOpen()
    if (!originalProfile?.profile_id) {
      return
    }
    const video_url = await uploadFile(
      apiClient,
      videoFile,
      FileUploadType.UPDATE_PROFILE_VIDEO,
      profile?.profile_id
    )
    const res = await apiClient.uploadProfileIntroVideo(
      video_url,
      originalProfile.profile_id
    )

    setVideoSrc(res.data.profile_intro_video_url)
    if (!!res.error || !res.data) {
      console.log("error uploading video")
      console.log(res.error)
    }
    setIsLoading(false)
    user.resetUser()
    editProfileUseEffect()
  }

  const modalProfileList = Object.keys(validateProfileObj).map(item => {
    // HERE
    if (!profile) return
    return (
      <ZiptiModalValidation
        title={item}
        validator={validateProfileObj[item]}
        profileItem={profile[item]}
      />
    )
  })

  const modalAccountList = Object.keys(validateAccountObj).map(item => {
    if (!user.data?.account) return
    return (
      <ZiptiModalValidation
        title={item}
        validator={validateAccountObj[item]}
        profileItem={user.data.account[item]}
      />
    )
  })

  const handleActivateSubmit = () => {
    const res = apiClient.markProfileActive(profile?.profile_id)
  }

  const renderCropperModal = () => {
    return (
      <ImageCropper
        image={tempProfileImage}
        name={tempPhotoName}
        onClose={onCropperClose}
      />
    )
  }

  const renderModal = () => {
    switch (modalType) {
      case MODAL_TYPES.NAVIGATE_TO_HOME:
        return (
          <Container mt="2rem" centerContent>
            <ZiptiText mt="2rem">
              You have unsaved changes on your profile. Do you want to discard
              them?
            </ZiptiText>
            <ZiptiButton
              mt="1rem"
              onClick={async () => {
                await conversations.resetConversations()
                navigate("/home")
              }}
            >
              Discard Changes
            </ZiptiButton>
            <ZiptiButton
              mt="1rem"
              onClick={() => {
                onClose()
              }}
            >
              Keep Editing
            </ZiptiButton>
          </Container>
        )
      case MODAL_TYPES.NAVIGATE_TO_PROFILE:
        return (
          <Container mt="2rem" centerContent>
            <ZiptiText mt="2rem">
              You have unsaved changes on your profile. Do you want to discard
              them?
            </ZiptiText>
            <ZiptiButton
              mt="1rem"
              onClick={async () => {
                await conversations.resetConversations()
                navigate(`/profile/${profile?.profile_id}`)
              }}
            >
              Discard Changes
            </ZiptiButton>
            <ZiptiButton
              mt="1rem"
              onClick={() => {
                onClose()
              }}
            >
              Keep Editing
            </ZiptiButton>
          </Container>
        )
    }
  }

  const renderDrawer = () => {
    switch (drawerType) {
      case DRAWER_TYPES.WATCH:
        return (
          <Container centerContent>
            <VideoPlayer videoSrc={videoSrc} />
          </Container>
        )
      case DRAWER_TYPES.UPLOAD:
        return (
          <VideoPreviewModal
            profileImageUrl={user.data?.account?.profile_image_url}
            videoSrc={videoSrc}
            networkSpeed={networkSpeed}
            isLoading={isLoading}
            loadingFileSize={loadingFileSize}
          />
        )
    }
  }

  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
    setVideoSrc(undefined)
  }

  const onModalOpen = () => {
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const openVideo = () => {
    setVideoSrc(profile?.profile_intro_video_url)
    setDrawerType(DRAWER_TYPES.WATCH)
    onDrawerOpen()
  }

  const openOnboarding = () => {
    onModalOpen()
  }

  const playExpertIntroVideo = () => {
    openVideo()
  }

  const mdContentCopyStyle = () => {
    return isAnimationActive ? "MdContentCopyActive" : "MdContentCopyOff"
  }

  const handleMdContentCopyClick = () => {
    navigator.clipboard.writeText(
      `${process.env.GATSBY_WWW_HOST}/profile/${profile?.profile_id}`
    )
    setIsAnimationActive(true)
    setTimeout(() => setIsAnimationActive(false), 300)
  }

  if (user.data?.account?.is_expert) {
    return (
      <Wrapper>
        <ZiptiButton
          position="absolute"
          mt="2.5vh"
          h="5vh"
          w="5vh"
          alignSelf="center"
          opacity="0"
          onClick={async () => {
            const accountSettingsAreEqual = name === user?.data?.account?.name
            const profilesAreEqual = _.isEqual(profile, originalProfile)
            if (!profilesAreEqual || !accountSettingsAreEqual) {
              setModalType(MODAL_TYPES.NAVIGATE_TO_HOME)
              onOpen()
              return
            }
            await conversations.resetConversations()
            navigate("/home")
          }}
        >
          CLICK
        </ZiptiButton>
        <ZiptiText mt="3rem" fontWeight="bold" fontSize="2.5vh">
          {yourProfileCopy}
        </ZiptiText>
        <ZiptiContainer
          h={{ base: "86rem", md: "108rem" }}
          paddingInlineStart="0rem"
          paddingInlineEnd="0rem"
          mt="3vh"
          {...props}
        >
          <Image
            mt="1rem"
            borderRadius="md"
            w="80%"
            h={{ base: "256px", md: "384px" }}
            src={user.data?.account?.profile_image_url}
            onClick={playExpertIntroVideo}
          />
          {profile?.profile_intro_video_url ? <VideoPlayIcon /> : null}
          <FileInput
            mt="2vh"
            buttonCopy={"Upload Profile Photo"}
            buttonProps={{ w: { base: "12rem", md: "16rem" } }}
            setFile={updateProfilePhoto}
            fileInputType={FileInputTypes.IMAGE}
          />
          <FileInput
            mt="2vh"
            buttonCopy={"Upload Intro Video"}
            buttonProps={{ w: { base: "12rem", md: "16rem" } }}
            setFile={updateIntroVideo}
            fileInputType={FileInputTypes.VIDEO}
          />
          <TitledInput
            checked={user.data.account.name}
            mt="5vh"
            h="10vh"
            label="Name"
          >
            <ZiptiInput value={name} onChange={updateName} />
          </TitledInput>
          <TitledInput
            checked={profile?.profile_instagram_link}
            mt="2vh"
            h="10vh"
            label="Instagram Link"
          >
            <ZiptiInput
              value={profile?.profile_instagram_link}
              onChange={updateInstagram}
            />
          </TitledInput>
          <TitledInput
            checkedOne={profile?.profile_offering_one}
            checkedTwo={profile?.profile_offering_two}
            checkedThree={profile?.profile_offering_three}
            mt="1rem"
            h="8rem"
            label="In your video you can offer..."
          >
            <ZiptiInput
              mt="0"
              h="20vh"
              placeholder="Advice on cooking..."
              value={profile?.profile_offering_one}
              onChange={updateOfferingOne}
            />
            <ZiptiInput
              mt="2vh"
              h="20vh"
              placeholder="Tips for drawing..."
              value={profile?.profile_offering_two}
              onChange={updateOfferingTwo}
            />
            <ZiptiInput
              mt="2vh"
              h="20vh"
              placeholder="Recommend techniques..."
              value={profile?.profile_offering_three}
              onChange={updateOfferingThree}
            />
          </TitledInput>
          <TitledInput
            checked={profile?.profile_about}
            mt="2vh"
            h="20vh"
            label="About"
          >
            <ZiptiTextarea
              value={profile?.profile_about}
              onChange={updateAbout}
            />
          </TitledInput>
          <TitledInput
            checked={profile?.profile_response_time}
            mt="2vh"
            h="10vh"
            label="Response Time"
          >
            <Select
              variant="outline"
              w={{ base: "14rem", md: "28rem" }}
              value={profile?.profile_response_time}
              onChange={updateResponseTime}
            >
              <option value={undefined}></option>
              <option value="12">12 Hours</option>
              <option value="24">24 Hours</option>
              <option value="48">48 Hours</option>
            </Select>
          </TitledInput>
          <TitledInput
            checked={profile?.currency_code}
            mt="2vh"
            h="10vh"
            label="Currency"
          >
            <Select
              variant="outline"
              w={{ base: "14rem", md: "28rem" }}
              value={profile?.currency_code}
              onChange={updateCurrencyCode}
            >
              {Object.keys(CURRENCIES).map(currency => {
                return <option value={currency}>{CURRENCIES[currency]}</option>
              })}
            </Select>
          </TitledInput>
          <TitledInput
            checked={verifyNumber(profile?.ask_price)}
            mt="2vh"
            h="10vh"
            label="Your Price"
          >
            <ZiptiNumberInput
              value={profile?.ask_price}
              setValue={updateAskPrice}
            />
          </TitledInput>
          <VStack textAlign="center">
            <ZiptiButton
              mt="3vh"
              onClick={async () => {
                const accountSettingsAreEqual =
                  name === user?.data?.account?.name
                const profilesAreEqual = _.isEqual(profile, originalProfile)
                if (!profilesAreEqual || !accountSettingsAreEqual) {
                  setModalType(MODAL_TYPES.NAVIGATE_TO_PROFILE)
                  onOpen()
                  return
                }
                await conversations.resetConversations()
                navigate(`/profile/${profile?.profile_id}`)
              }}
            >
              Go to Profile
            </ZiptiButton>
            <ZiptiButton
              className={mdContentCopyStyle()}
              onClick={() => handleMdContentCopyClick()}
              style={{
                marginTop: "2vh",
                cursor: "pointer",
              }}
            >
              Copy Profile Link
            </ZiptiButton>
          </VStack>
        </ZiptiContainer>
        {/* Stripe Payments Setup */}
        {renderStripeStatusCardHeader()}
        {renderStripeStatusCard()}
        {/* Account Setup */}
        <ZiptiText mt="5vh" fontWeight="bold" fontSize="2.5vh">
          {yourAccountCopy}
        </ZiptiText>
        <ZiptiContainer
          h={
            email !== user.data?.account?.email && email?.length > 0
              ? "14rem"
              : "9rem"
          }
          mt="2vh"
        >
          <TitledInput mt="1.5rem" checked={" "} label="Email">
            <ZiptiInput
              borderColor={emailValidationToggle ? "red" : null}
              onChange={(e: any) => handleEmailChange(e)}
              placeholder={user.data?.account?.email.toLowerCase()}
            />
            {emailValidationToggle ? (
              <ZiptiText mt="1rem">Must be a valid email...</ZiptiText>
            ) : null}
            {email !== user.data?.account?.email && email?.length > 0 ? (
              <ZiptiButton mt="1rem" onClick={handleUpdateEmailSubmit}>
                Update Email
              </ZiptiButton>
            ) : null}
          </TitledInput>
        </ZiptiContainer>
        <ZiptiDrawer
          onClose={onDrawerClose}
          isOpen={isDrawerOpen}
          renderDrawer={renderDrawer}
        />
        {renderActivateExpertButton()}
        {renderSaveButton()}
        {modalType !== MODAL_TYPES.CROPPER ? (
          <ZiptiModal
            isOpen={isOpen}
            onClose={onClose}
            renderModal={renderModal}
          />
        ) : (
          <ZiptiCropperModal
            isOpen={isOpen}
            onClose={onClose}
            renderModal={renderCropperModal}
          />
        )}
        {toggleSpinner ? (
          <Spinner
            position="fixed"
            top="48%"
            left="48%"
            color={COLORS.PURPLE}
          />
        ) : null}
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <ZiptiContainer
          h={{ base: "52rem", md: "62rem" }}
          paddingInlineStart="0rem"
          paddingInlineEnd="0rem"
          mt="3vh"
          {...props}
        >
          <ZiptiText mt="3rem" fontWeight="bold" fontSize="2.5vh">
            {yourProfileCopy}
          </ZiptiText>
          <Image
            mt="1rem"
            borderRadius="md"
            w="80%"
            h={{ base: "256px", md: "384px" }}
            src={user.data?.account?.profile_image_url}
          />
          <FileInput
            mt="2vh"
            buttonCopy={"Upload Profile Photo"}
            buttonProps={{ w: { base: "12rem", md: "16rem" } }}
            setFile={updateProfilePhoto}
            fileInputType={FileInputTypes.IMAGE}
          />
          <TitledInput account={true} mt="5vh" h="10vh" label="Name">
            <ZiptiInput value={name} onChange={updateName} />
          </TitledInput>
          <TitledInput account={true} mt="1.5rem" label="Email">
            <ZiptiInput
              borderColor={emailValidationToggle ? "red" : null}
              onChange={(e: any) => handleEmailChange(e)}
              placeholder={user.data?.account?.email}
            />
            {emailValidationToggle ? (
              <ZiptiText mt="1rem">Must be a valid email...</ZiptiText>
            ) : null}
            {email !== user.data?.account?.email && email?.length > 0 ? (
              <ZiptiButton mt="1rem" onClick={handleUpdateEmailSubmit}>
                Update Email
              </ZiptiButton>
            ) : null}
          </TitledInput>
          {renderSaveButton()}
          {toggleSpinner ? (
            <Spinner
              position="fixed"
              top="48%"
              left="48%"
              color={COLORS.PURPLE}
            />
          ) : null}
        </ZiptiContainer>
      </Wrapper>
    )
  }
}

export default EditProfile

import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal"
import React from "react"

export interface ZiptiModalProps {
  isOpen: any
  onClose: any
  renderModal: any
}

const ZiptiCropperModal: React.FC<ZiptiCropperModalProps> = props => {
  return (
    <Modal
      size={"md"}
      height="700px"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent mt="30vh" h="33vh" w="90vw">
        <ModalCloseButton />
        {props.renderModal()}
      </ModalContent>
    </Modal>
  )
}

export default ZiptiCropperModal

import React from "react"
import { Input } from "@chakra-ui/input"
import { Box } from "@chakra-ui/layout"
import ZiptiButton from "../zipti_button"
import { useRef } from "react"
import { navigate } from "gatsby-link"

export interface FileInputProps {
  fileInputType: FileInputTypes
  buttonCopy: string
  buttonProps: object
  setFile: any
  position?: string
  top?: string
  left?: string
  mt?: string
  uploadPhoto: boolean
}

export enum FileInputTypes {
  VIDEO,
  IMAGE,
}

// TODO allow uploading photo through gallery
//  currently we go straight to camera on phones for photo upload

export const FileInput: React.FC<FileInputProps> = props => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files || e.target.files.length === 0) {
      return
    }
    props.setFile(e.target.files[0])
  }

  const getAccept = () => {
    if (props.fileInputType === FileInputTypes.VIDEO) {
      return "video/*"
    }

    if (props.fileInputType === FileInputTypes.IMAGE) {
      return "image/*"
    }

    return "*"
  }

  const hiddenFileInput = useRef(null)
  const handleClick = e => {
    hiddenFileInput.current.click()
  }

  return (
    // @ts-ignore
    <Box {...props}>
      <Input
        display="none"
        id="file-input"
        type="file"
        ref={hiddenFileInput}
        accept={getAccept()}
        capture="user"
        onChange={handleChange}
      />
      <ZiptiButton onClick={handleClick} {...props.buttonProps}>
        {props.buttonCopy}
      </ZiptiButton>
    </Box>
  )
}

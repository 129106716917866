import { Textarea } from "@chakra-ui/textarea"
import React from "react"

interface ZiptiTextareaProps {
  value?: string
  onChange?: any
  position?: string
  top?: string
  placeholder?: string
}

const ZiptiTextarea: React.FC<ZiptiTextareaProps> = (
  props: ZiptiTextareaProps
) => {
  return (
    <Textarea
      w={{ base: "14rem", md: "28rem" }}
      h={{ base: "10rem", md: "10rem" }}
      border="2px solid #E9E9E9"
      borderRadius="10px"
      {...props}
    />
  )
}

export default ZiptiTextarea

import React from "react"
import { Image } from "@chakra-ui/image"
import playTriangle from "../../../images/playTriangle.svg"

const VideoPlayIcon: React.FC = props => {
  return (
    <Image
      src={playTriangle}
      opacity="0.6"
      mr={{ base: "12rem", md: "18rem" }}
      mt="-15%"
      mb={{ base: "5%", md: "10%" }}
    />
  )
}

export default VideoPlayIcon

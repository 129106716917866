import React from "react"

import { Input } from "@chakra-ui/input"

interface ZiptiInputProps {
  value?: string
  placeholder?: string
  mt?: string
  w?: string
  onChange?: any
}

const ZiptiInput: React.FC<ZiptiInputProps> = props => {
  return (
    <Input
      border="2px solid #E9E9E9"
      borderRadius="24px"
      w={{ base: "14rem", md: "28rem" }}
      h="6vh"
      fontSize="1.5vh"
      fontWeight="bold"
      {...props}
    />
  )
}

export default ZiptiInput
